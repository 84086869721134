import { Linky } from "../components/linky"

export const EwaSupport = () => {
  return <div>
    <h1>NotifyWA - Support</h1>
    <h2>Introduction</h2>
    <p>This app uses publically available information available at <Linky to="https://www.emergency.wa.gov.au" text="emergency.wa.gov.au" />. This app is purely for informational purposes and comes with no warranty of accuracy </p>
    <p>This app is not in any way endorsed by Emergency WA or DFES.</p>
    <h2>Enquiries</h2>
    <p>If you have any queries please contact me at <Linky to="mailto:hello@ben-k.dev" text="hello@ben-k.dev" /></p>
  </div>
}
